import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Hooks
import { useWindowScroll } from "react-use";
// Context
import { PageTypeConsumer } from "../context/page-type";

// Components
import { DesktopSlideOutMenu } from "../navigation/desktop-slide-out-menu";

// Icons
import { ParkVillageIconOutline } from "../icons/park-village-icon-outline";
import { ParkVillageIcon } from "../icons/park-village-icon";
import { RoomSixLogo } from "../icons/room-six-logo";
import { StudiosIcon } from "../icons/studios-icons";
import { EventsIcon } from "../icons/events-icons";
import { BackIcon } from "../icons/back-icon";
import { BackArrow } from "../icons/back-arrow";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 30px;

  z-index: 200;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
  position: relative;

  color: ${props => props.color};

  & .flex-start {
    & svg {
      margin: 0 auto 0 0;
      display: block;
    }
  }

  & .flex-center {
    & svg {
      margin: 0 auto;
      display: block;
    }
  }

  & .flex-end {
    & svg {
      margin: 0 0 0 auto;
      display: block;
    }
  }
`;

const Item = styled.div`
  display: ${props => props.display};

  position: absolute;
`;

const ItemLeft = styled(Item)`
  left: 0;
`;

const ItemRight = styled.div`
  position: absolute;
  right: 0;
`;

const ItemCentred = styled(Item)`
  left: 50%;
  transform: translateX(-50%);
`;

export const DesktopMenu = ({ location }) => {
  const [shrinkLogo, setShrinkLogo] = useState(false);

  const { x, y } = useWindowScroll();

  useEffect(() => {
    if (y > 200) {
      setShrinkLogo(true);
    } else {
      setShrinkLogo(false);
    }
  }, [y, setShrinkLogo]);

  return (
    <HeaderWrapper>
      <PageTypeConsumer>
        {({ pageType }) => {
          let textColor = `rgba(250,251,246,0.5)`;
          if (pageType === `video`) {
            textColor = `rgba(250,251,246,0.5)`;
          } else if (
            pageType === `directors` ||
            pageType === `experiential` ||
            pageType === `events` ||
            pageType === `event` ||
            pageType === `textpage` ||
            pageType === `the-talent`
          ) {
            textColor = `#fff`;
          } else {
            textColor = `#1D1D1B`;
          }

          return (
            <Nav color={textColor}>
              {pageType === "video" && (
                <ItemLeft>
                  <BackIcon fill={textColor} />
                </ItemLeft>
              )}
              {pageType === "article" && (
                <ItemLeft>
                  <BackIcon fill={textColor} backButtonURL={`/stories`} />
                </ItemLeft>
              )}

              {pageType === "photographer" && (
                <ItemLeft>
                  <BackIcon
                    fill={textColor}
                    backButtonURL={
                      location.state !== undefined &&
                      location.state !== null &&
                      location.state.from !== undefined
                        ? location.state.from
                        : `/photography/`
                    }
                  />
                </ItemLeft>
              )}

              {pageType === "single-photography-project" && (
                <ItemLeft>
                  <BackArrow
                    fill={textColor}
                    backButtonURL={
                      location.state !== undefined &&
                      location.state !== null &&
                      location.state.from !== undefined
                        ? location.state.from
                        : `/photography/`
                    }
                  />
                </ItemLeft>
              )}

              {pageType === "studios" && (
                <ItemLeft>
                  <EventsIcon />
                </ItemLeft>
              )}
              {(pageType === "event" || pageType === "page") && (
                <ItemLeft>
                  <StudiosIcon fill={textColor} />
                </ItemLeft>
              )}
              {pageType !== "event" &&
                (pageType === "directors" ||
                  pageType === "events" ||
                  pageType === "textpage" ||
                  pageType === "the-talent") && (
                  <ItemCentred display={`block`}>
                    <ParkVillageIconOutline
                      fill={textColor}
                      shrinkLogo={shrinkLogo}
                    />
                  </ItemCentred>
                )}
              {pageType !== "directors" &&
                pageType !== "event" &&
                pageType !== "experiential" &&
                pageType !== "events" &&
                pageType !== "video" &&
                pageType !== "textpage" &&
                pageType !== "the-talent" &&
                pageType !== "single-photography-project" && (
                  <ItemCentred className="flex-1" display={`block`}>
                    <ParkVillageIcon fill={textColor} shrinkLogo={shrinkLogo} />
                  </ItemCentred>
                )}
              {pageType === "experiential" && (
                <ItemCentred display={`block`}>
                  <RoomSixLogo shrinkLogo={shrinkLogo} />
                </ItemCentred>
              )}
              <DesktopSlideOutMenu
                location={location.pathname}
                textColor={textColor}
              />
            </Nav>
          );
        }}
      </PageTypeConsumer>
    </HeaderWrapper>
  );
};
