import React, { useContext } from "react";
import styled from "styled-components";
import Media from "react-media";

// Context
import WindowWidthContext from "../context/window-width";
import HeaderOpacityContext from "../context/header-opacity";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

export const Header = ({ location }) => {
  const windowWidth = useContext(WindowWidthContext);
  const headerOpacity = useContext(HeaderOpacityContext);

  return (
    <>
      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => <MobileMenu location={location} />}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <DesktopMenu location={location} headerOpacity={headerOpacity} />
        )}
      />
    </>
  );
};
