import React, { useContext } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Context
import WindowWidthContext from "../context/window-width";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  padding: 100px 20px 10px 20px;

  z-index: 1;

  color: ${props => props.fillColor};

  @media (max-width: 860px) {
    padding: 135px 20px 10px 20px;
  }
`;

const SocialLinksContainer = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  width: 100%;

  margin: 0 0 6px 0;

  li {
    width: 116px;
    height: 30px;

    transition: 150ms all ease;

    border: 1px solid ${props => props.fillColor};
    border-radius: 1px;

    margin: 0 15px;

    @media (max-width: 860px) {
      margin: 0 3px;
    }

    p {
      width: 100%;

      text-align: center;

      margin: 0;

      a {
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-family: "LDNBloomsburyOldStyle-DemiBold", Times, "Times New Roman",
          serif;

        display: block;

        padding: 0 10px;

        color: ${props => props.fillColor};
      }
    }

    &:hover {
      border: 1px solid transparent;
      background: ${props => props.fillColor};

      & a {
        color: ${props => props.linkHoverColor};
      }
    }
  }

  .logo {
    margin: 0 85px;

    & svg {
      height: 90px;
      width: auto;
    }

    @media (max-width: 860px) {
      width: 100%;
      text-align: center;

      margin: 0 0 35px 0;
    }
  }

  & .breaker {
    display: block;
    width: 100%;
    height: 6px;
  }

  @media (max-width: 860px) {
    margin: 0 0 37px 0;
  }
`;

const Address = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.04em;

  @media (max-width: 860px) {
    margin: 0 0 25px 0;
  }

  & .address {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin: 30px 0 5px 0;

    @media (max-width: 860px) {
      display: block;
      text-align: center;
    }

    @media (max-width: 768px) {
      margin: 30px 0;
    }

    & p {
      margin: 0;

      &::after {
        content: ",";
        padding: 0 5px 0 0;

        @media (max-width: 860px) {
          display: none;
        }
      }

      &:last-of-type {
        ::after {
          content: "";
          padding: 0;
        }
      }
    }
  }

  & .contact-details {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    margin: 0;

    & div {
      & p {
        margin: 0;
      }
    }

    & .spacer {
      margin: 0 7.5px;
    }

    @media (max-width: 860px) {
      display: block;
      text-align: center;

      & .spacer {
        display: none;
      }
    }
  }
`;

const Links = styled.div`
  width: 100%;

  margin: 15px 0 0 0;

  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.08em;

  @media (max-width: 860px) {
    margin: 15px 0 13px 0;
  }

  & .menu-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    padding: 0;
    margin: 0;

    & li {
      text-transform: uppercase;
      letter-spacing: 0.05em;

      &::after {
        content: "–";
        padding: 0 7.5px;

        @media (max-width: 860px) {
          display: none;
        }
      }

      &:last-of-type {
        &::after {
          content: "";
          padding: 0;
        }
      }

      @media (max-width: 860px) {
        width: 100%;
        text-align: center;

        line-height: 20px;
      }
    }
  }
`;

const BottomLinks = styled(Links)`
  margin: 40px 0 12px 0;

  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-align: center;

  opacity: 0.5;

  & .menu-links {
    & li {
      letter-spacing: 0.04em;

      &::after {
        content: "・ ";
        padding: 0;
        margin: 0 7.5px;

        @media (max-width: 860px) {
          display: inline-block;
        }

        @media (max-width: 500px) {
          display: none;
        }
      }

      &:last-of-type {
        &::after {
          content: "";
          padding: 0;
        }
      }

      @media (max-width: 860px) {
        width: auto;
        text-align: left;
      }

      @media (max-width: 500px) {
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export const Footer = ({ location, pageType }) => {
  const windowWidth = useContext(WindowWidthContext);

  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          footer_menu_links {
            menu_item {
              document {
                ... on PrismicDirectors {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicFoodDirectors {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicExperiential {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPage {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicEvents {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPhotographers {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicStudios {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          address_line_one
          address_line_two
          address_line_three
          social_link {
            html
          }
          phone_number {
            html
          }
          email_address {
            html
          }
          footer_social_links {
            link {
              html
            }
          }
          bottom_menu_links {
            link {
              document {
                ... on PrismicPage {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicTextPage {
                  id
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menuLinks = data.prismicMenu.data.footer_menu_links.map(
    (item, index) => (
      <li key={`menu_link_${index}`}>
        <Link to={`/${item.menu_item.document.uid}`}>
          {item.menu_item.document.data.title.text}
        </Link>
      </li>
    )
  );

  const bottomMenuLinks = data.prismicMenu.data.bottom_menu_links.map(
    (item, index) => (
      <li key={`bottom_menu_link_${index}`}>
        <Link to={`/${item.link.document.uid}`}>
          {item.link.document.data.title.text}
        </Link>
      </li>
    )
  );

  let fillColor;
  if (
    pageType === "directors" ||
    pageType === "experiential" ||
    pageType === "events" ||
    pageType === "event" ||
    pageType === "textpage"
  ) {
    fillColor = "rgba(253, 252, 252, 0.8)";
  } else {
    fillColor = "#000";
  }

  let linkHoverColor;
  if (
    pageType === "directors" ||
    pageType === "experiential" ||
    pageType === "events" ||
    pageType === "event"
  ) {
    linkHoverColor = "#1d1d1b";
  } else if (pageType === "video") {
    linkHoverColor = "#000";
  } else if (pageType === "textpage") {
    linkHoverColor = "#177f96";
  } else {
    linkHoverColor = "#fff";
  }

  return (
    <FooterWrapper fillColor={fillColor}>
      <Links>
        <ol className="menu-links">{menuLinks}</ol>
      </Links>

      <Address>
        <div className="address">
          <p>{data.prismicMenu.data.address_line_one}</p>
          <p>{data.prismicMenu.data.address_line_two}</p>
          <p>{data.prismicMenu.data.address_line_three}</p>
        </div>
        <div className="contact-details">
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicMenu.data.social_link.html,
            }}
          />
          <span className="spacer">・</span>
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicMenu.data.phone_number.html,
            }}
          />
          <span className="spacer">・</span>
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicMenu.data.email_address.html,
            }}
          />
        </div>
      </Address>

      <BottomLinks>
        <ol className="menu-links">{bottomMenuLinks}</ol>
      </BottomLinks>
    </FooterWrapper>
  );
};
