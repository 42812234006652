import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Icons
import { MenuSpike } from "../icons/menu-spike";

const ItemRight = styled.div`
  position: absolute;
  right: 0;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const MenuToggle = styled.button`
  opacity: ${props => props.opacity};
  display: ${props => props.display};
  margin: 0 0 0 auto;

  width: 36px;
  height: 36px;
  position: relative;

  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  z-index: 100;

  & span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;

    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 12px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 24px;
      transform-origin: left center;
    }
  }

  &.open {
    & span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 0px;
        left: 6px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 26px;
        left: 6px;
      }
    }
  }
`;

const SlideDownMenu = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;

  z-index: 30;

  background-color: #df3f1c;

  // transform: translateY(calc(-100% - 10px - 10px - 10px));
  // transition: transform 0.3s ease-in;

  opacity: 0%;
  transform: scale(0);
  transform-origin: top right;
  transition: transform 0.3s ease-in, opacity 0.3s ease-in;

  min-width: 350px;
  overflow-y: scroll;

  @media (max-width: 1300px) {
    min-width: 270px;
  }

  clip-path: polygon(
    0% 8px,
    8px 0%,
    calc(100% - 8px) 0%,
    100% 8px,
    100% calc(100% - 8px),
    calc(100% - 8px) 100%,
    8px 100%,
    0 calc(100% - 8px)
  );

  &.open {
    // transform: translateY(0%);
    opacity: 90%;
    min-width: 350px;
    transform: scale(1);
  }

  & .menu-items {
    padding: 60px;

    height: 100%;
    max-height: calc(100vh - 120px - 20px - 20px);
    overflow-y: scroll;
  }

  & ol {
    padding: 0;
    margin: 0;
  }

  & li,
  & button {
    font-size: calc(20px + (36 - 20) * ((100vh - 400px) / (1000 - 400)));
    line-height: 120%;

    // font-size: 36px;
    // line-height: 46px;
    color: rgba(253, 252, 252, 0.8);

    text-align: center;

    @media (max-width: 1300px) {
      font-size: 28px;
      line-height: 36px;
    }
  }

  & li.divider {
    & svg {
      margin: 15px auto;
    }
  }

  & .sub-menu {
    margin: 5px 0 0 0;
  }

  & li.sub-menu-item {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0.02em;

    @media (max-width: 1300px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
`;

export const DesktopSlideOutMenu = ({ location, textColor }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [isDotMenuOpen, setIsDotMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
    setActiveSubMenu(null);
    setIsDotMenuOpen(false);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          body2 {
            ... on PrismicMenuBody2Divider {
              id
              slice_type
            }
            ... on PrismicMenuBody2PageLink {
              id
              slice_type
              primary {
                link {
                  document {
                    ... on PrismicDirectors {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicContact {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCommunity {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStories {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicEvents {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicExperiential {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicWhatWeDo {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicMission {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPhotographers {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicTheTalent {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStudios {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicMenuBody2ItemWithSubmenu {
              id
              slice_type
              primary {
                menu_item_title
              }
              items {
                override_page_title
                alternative_page_title
                page_link {
                  document {
                    ... on PrismicDirectors {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicContact {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCommunity {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicEvents {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStories {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicWhatWeDo {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicExperiential {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicMission {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPhotographers {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicTheTalent {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStudios {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const links = data.prismicMenu.data.body2.map((link, index) => {
    if (link.slice_type === "divider") {
      return (
        <li key={link.id} className="divider">
          <div>
            <MenuSpike fill={`rgba(253,252,252,0.8)`} />
          </div>
        </li>
      );
    }

    if (link.slice_type === "page_link") {
      return (
        <li key={link.id}>
          <Link to={link.primary.link.document.url}>
            {link.primary.link.document.data.title.text}
          </Link>
        </li>
      );
    }

    if (link.slice_type === "item_with_submenu") {
      const subMenuItems = link.items.map((subMenuLink, index) => (
        <li key={subMenuLink.page_link.document.id} className="sub-menu-item">
          <Link to={subMenuLink.page_link.document.url}>
            {subMenuLink.override_page_title ? (
              <> {subMenuLink.alternative_page_title}</>
            ) : (
              <> {subMenuLink.page_link.document.data.title.text}</>
            )}
          </Link>
        </li>
      ));

      return (
        <li key={link.id}>
          <button
            onClick={() => {
              if (activeSubMenu === link.id) {
                setActiveSubMenu(null);
              } else {
                setActiveSubMenu(link.id);
              }
            }}
          >
            {link.primary.menu_item_title}
          </button>
          {activeSubMenu === link.id && (
            <ol className="sub-menu">{subMenuItems}</ol>
          )}
        </li>
      );
    }
  });

  return (
    <>
      <ItemRight className="flex-end" display={`flex`}>
        <MenuToggle
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          color={textColor}
          opacity={isDotMenuOpen === true ? 0 : 1}
          className={isMenuOpen === true ? `open` : ``}
        >
          <span>
            <MenuSpike fill={isMenuOpen ? `#FDFCFC` : textColor} />
          </span>
          <span>
            <MenuSpike fill={isMenuOpen ? `#FDFCFC` : textColor} />
          </span>
          <span>
            <MenuSpike fill={isMenuOpen ? `#FDFCFC` : textColor} />
          </span>
        </MenuToggle>
      </ItemRight>

      <SlideDownMenu className={isMenuOpen === true ? `open` : ``}>
        <ul className="menu-items">{links}</ul>
      </SlideDownMenu>
    </>
  );
};
