import React from "react";

export const headerOpacity = {
  headerOpacity: 1,
  updateHeaderOpacity: () => {},
};

const HeaderOpacityContext = React.createContext(headerOpacity);

export default HeaderOpacityContext;
