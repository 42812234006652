import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  display: block;
`;

export const MenuSpike = ({ fill }) => (
  <Icon
    width="37"
    height="4"
    viewBox="0 0 37 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.6666 1.31517L36.6666 2.81522L18.3333 3.56522L0 2.81522V1.31517L18.3333 0.565216L36.6666 1.31517Z"
      fill={fill}
    />
  </Icon>
);
