import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Context
import WindowWidthContext from "../context/window-width";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Cookie } from "../cookies/cookie";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, data, children, pageType, bgColor }) => {
  useEffect(() => {
    document.body.style.backgroundColor = bgColor;
  }, [bgColor]);

  // Window Width
  const [updatedWindowWidth, setWindowWidth] = useState(768);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  return (
    <WindowWidthContext.Provider value={updatedWindowWidth}>
      {pageType !== "video" && pageType !== "event" && (
        <Header location={location} />
      )}

      <Main className={pageType}>{children}</Main>
      {location.pathname !== "/" &&
        pageType !== "video" &&
        pageType !== "home" &&
        pageType !== "the-talent" && (
          <Footer location={location.pathname} pageType={pageType} />
        )}

      <Cookie />
    </WindowWidthContext.Provider>
  );
};
