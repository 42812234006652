import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
// import AniLink from "gatsby-plugin-transition-link/AniLink";

const Icon = styled.svg`
  transition: 500ms height ease;
  height: ${props => props.height};

  @media (max-width: 768px) {
    width: auto;
    height: 25px;

    transition: 250ms opacity ease, 300ms transform ease;
    opacity: ${props => props.opacity};

    &.visible {
      transform: translateY(0);
    }

    &.hidden {
      transform: translateY(-90px);
    }
  }
`;

export const RoomSixLogo = ({ fadeOutLogo, shrinkLogo }) => (
  <Link to={`/`} className="flex-center">
    <Icon
      width="450"
      height={shrinkLogo === true ? `50px` : `82px`}
      viewBox="0 0 450 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={fadeOutLogo === true ? 0 : 1}
      className={shrinkLogo === true ? `hidden` : `visible`}
    >
      <path
        d="M332.444 0.574341H299.605V49.0789L281.69 1.55872L281.32 0.574341H248.477V80.9711H362.757L332.815 1.55872L332.444 0.574341ZM329.358 3.61998V71.2902L303.842 3.61998H329.358ZM278.234 3.61998V71.2902L252.699 3.61998H278.234ZM251.522 77.9255V9.09326L277.475 77.9255H251.522ZM281.272 77.9255V9.09326L299.605 57.731V77.944L281.272 77.9255ZM302.65 77.9255V65.7873L307.224 77.9255H302.65ZM310.481 77.9255L302.65 57.1537V9.08955L328.603 77.9218L310.481 77.9255ZM332.4 77.9255V9.09326L358.353 77.9255H332.4Z"
        fill="#FDFCFC"
      />
      <path
        d="M442.228 36.2666C437.753 31.6386 431.733 28.8173 425.313 28.3398L425.716 0.773608L424.176 0.740302H423.577C413.408 0.734639 403.616 4.59329 396.184 11.5351C388.753 18.4769 384.236 27.9831 383.55 38.1294C382.863 48.2756 386.057 58.3037 392.486 66.1836C398.914 74.0634 408.097 79.2062 418.174 80.5709C419.729 80.8746 421.308 81.0405 422.892 81.0668H423.292C428.48 81.0641 433.553 79.5332 437.876 76.6652C442.2 73.7972 445.583 69.7192 447.603 64.9403C449.622 60.1615 450.19 54.8935 449.234 49.794C448.278 44.6946 445.842 39.9896 442.228 36.2666ZM406.524 70.9381C403.392 67.709 401.259 63.6442 400.382 59.2321C399.504 54.82 399.919 50.2484 401.577 46.0665C403.235 41.8846 406.065 38.2704 409.728 35.6582C413.39 33.046 417.729 31.5469 422.223 31.341L421.505 77.8361C420.542 77.7843 419.591 77.6918 418.648 77.566C414.02 76.6435 409.785 74.3312 406.506 70.9381H406.524ZM386.541 40.3003C386.691 30.7403 390.527 21.6074 397.248 14.8074C403.969 8.00743 413.057 4.0656 422.615 3.80445L422.245 28.2769C417.301 28.4658 412.509 30.0405 408.417 32.8213C404.324 35.6022 401.096 39.4772 399.1 44.0044C397.103 48.5316 396.42 53.5287 397.127 58.4258C397.833 63.3228 399.902 67.9226 403.098 71.7004C397.93 68.271 393.706 63.5989 390.813 58.1127C387.92 52.6265 386.451 46.5018 386.541 40.3003ZM439.538 71.4229C435.51 75.356 430.187 77.6871 424.565 77.9804L425.246 31.3929C429.71 31.7731 433.97 33.429 437.519 36.163C441.068 38.897 443.755 42.5939 445.262 46.813C446.768 51.032 447.029 55.5952 446.014 59.9586C445 64.322 442.751 68.3016 439.538 71.4229Z"
        fill="#FDFCFC"
      />
      <path
        d="M197.781 0.984253C189.871 0.984253 182.139 3.3298 175.562 7.72429C168.985 12.1188 163.859 18.3648 160.832 25.6726C157.805 32.9804 157.013 41.0216 158.557 48.7795C160.1 56.5374 163.909 63.6635 169.502 69.2566C175.095 74.8497 182.221 78.6587 189.979 80.2018C197.737 81.745 205.778 80.953 213.086 77.926C220.394 74.899 226.64 69.773 231.034 63.1962C235.429 56.6194 237.774 48.8871 237.774 40.9773C237.762 30.3741 233.545 20.2085 226.047 12.7109C218.55 5.21332 208.384 0.996007 197.781 0.984253ZM197.781 77.9246C190.474 77.9246 183.33 75.7577 177.254 71.6979C171.178 67.6381 166.443 61.8677 163.646 55.1164C160.85 48.3652 160.118 40.9363 161.544 33.7692C162.969 26.6021 166.488 20.0187 171.655 14.8515C176.823 9.68435 183.406 6.16545 190.573 4.73983C197.74 3.31421 205.169 4.04589 211.92 6.84235C218.671 9.63881 224.442 14.3744 228.502 20.4504C232.562 26.5264 234.728 33.6698 234.728 40.9773C234.713 50.7715 230.815 60.1601 223.89 67.0857C216.964 74.0113 207.575 77.909 197.781 77.9246Z"
        fill="#FDFCFC"
      />
      <path
        d="M109.783 0.985201C105.553 0.979815 101.348 1.64685 97.327 2.96135V2.93175L95.9059 3.45724L94.4737 3.99013V4.01974C84.8986 8.0048 77.2461 15.5537 73.1308 25.0735C69.0155 34.5934 68.7594 45.3396 72.4165 55.0447C76.0735 64.7498 83.3577 72.6547 92.7321 77.0914C102.106 81.5281 112.838 82.1497 122.662 78.8248V78.8581L124.09 78.3104L125.504 77.7664V77.7405C133.969 74.1145 140.923 67.6781 145.193 59.5184C149.462 51.3586 150.784 41.9756 148.936 32.9537C147.089 23.9319 142.184 15.8242 135.051 9.99984C127.917 4.17547 118.992 0.991348 109.783 0.985201ZM109.783 77.9256C101.241 77.9307 92.9604 74.9759 86.351 69.5638C79.7416 64.1518 75.2116 56.6169 73.5316 48.2412C71.8517 39.8655 73.1257 31.1664 77.1368 23.6242C81.1479 16.082 87.6484 10.1625 95.5321 6.87295L121.588 75.979C117.786 77.2675 113.798 77.9251 109.783 77.9256ZM140.073 62.1349C136.147 67.7607 130.73 72.1807 124.431 74.8984L104.599 22.2973L98.389 5.82566C105.754 3.43932 113.682 3.42646 121.055 5.78892C128.427 8.15138 134.872 12.7698 139.478 18.9922C144.084 25.2146 146.62 32.7265 146.726 40.4676C146.833 48.2087 144.506 55.7878 140.073 62.1349Z"
        fill="#FDFCFC"
      />
      <path
        d="M59.9097 28.3991C59.9019 21.0048 56.9613 13.9155 51.733 8.68657C46.5048 3.45763 39.4159 0.516089 32.0215 0.507273L0.329102 0.348145V80.9707H63.4882L51.2761 48.564C54.0028 45.9604 56.1736 42.8313 57.6575 39.3655C59.1414 35.8998 59.9075 32.1692 59.9097 28.3991ZM32.0104 3.55291C38.548 3.6327 44.7908 6.28573 49.3856 10.937C53.9804 15.5882 56.5571 21.8629 56.5571 28.401C56.5571 34.9391 53.9804 41.2137 49.3856 45.865C44.7908 50.5162 38.548 53.1693 32.0104 53.249H23.2584L4.15558 3.41229L32.0104 3.55291ZM3.37474 77.9361V9.90324L20.7678 55.314L21.0713 56.0541L29.3127 77.9139L3.37474 77.9361ZM32.5877 77.9361L24.4241 56.291H32.0104C32.4138 56.291 32.8172 56.291 33.2205 56.2651L33.6165 56.2392C33.8867 56.2392 34.1568 56.2096 34.427 56.1874L34.8932 56.1393C35.1375 56.1133 35.3817 56.0874 35.6334 56.0541L36.1256 55.9838C36.3575 55.9493 36.5906 55.9123 36.825 55.8728L37.3209 55.7803L38.0129 55.636L38.4977 55.5286C38.7308 55.4731 38.9603 55.4102 39.1934 55.351L39.656 55.2252C39.9027 55.1561 40.1494 55.0821 40.3961 55.0032L40.8032 54.8736C41.103 54.7737 41.4027 54.6664 41.6988 54.5591L41.9282 54.4777C42.2983 54.3333 42.6683 54.1853 43.0384 54.0299L43.3418 53.893L44.1227 53.5229L44.5224 53.3194C44.7444 53.2083 44.9701 53.0973 45.1885 52.9789L45.6141 52.7457L46.2358 52.3757L46.6576 52.1203C46.8612 51.9945 47.061 51.865 47.2608 51.7503C47.4015 51.6615 47.5384 51.5689 47.6753 51.4764L48.2674 51.0619L48.6597 50.777L48.8225 50.6586L59.0992 77.9176L32.5877 77.9361Z"
        fill="#FDFCFC"
      />
    </Icon>
  </Link>
);
