import React, { useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import smoothscroll from "smoothscroll-polyfill";

// Context
import { PageTypeProvider, PageTypeConsumer } from "../context/page-type";

// Components
import GlobalStyles from "../styles/globalStyles";
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Container = styled.div`
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;
`;

const Layout = ({ children }) => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <PageTypeProvider>
      <PageTypeConsumer>
        {({ pageType }) => {
          let bgColor;
          if (
            pageType === "directors" ||
            pageType === "experiential" ||
            pageType === "the-talent" ||
            pageType === "events"
          ) {
            bgColor = "#1d1d1b";
          } else if (pageType === "video" || pageType === "event") {
            bgColor = "#000";
          } else if (pageType === "textpage") {
            bgColor = "#177f96";
          } else if (
            pageType === "photography" ||
            pageType === "photographer" ||
            pageType === "single-photography-project"
          ) {
            bgColor = "#fff";
          } else {
            bgColor = "#F7F7F3";
          }
          return (
            <Location>
              {({ location }) => (
                <Container location={location.pathname}>
                  <Normalize />
                  <GlobalStyles />
                  <DefaultSEO />
                  <App
                    location={location}
                    children={children}
                    pageType={pageType}
                    bgColor={bgColor}
                  />
                </Container>
              )}
            </Location>
          );
        }}
      </PageTypeConsumer>
    </PageTypeProvider>
  );
};

export default Layout;
