import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Icons
import { MenuSpike } from "../icons/menu-spike";

const MenuToggle = styled.button`
  display: ${props => props.display};
  margin: 0 0 0 auto;

  width: 36px;
  height: 36px;
  position: relative;

  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;

  z-index: 100;

  & span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;

    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 12px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 24px;
      transform-origin: left center;
    }
  }

  &.open {
    & span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 0px;
        left: 6px;
      }

      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 26px;
        left: 6px;
      }
    }
  }
`;

const Menu = styled.div`
  display: ${props => props.display};
  position: fixed;
  top: -10px;
  right: -10px;

  z-index: 2;

  background-color: #df3f1c;

  &.open {
    width: 100%;
    height: 100%;

    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  & .menu-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;

    height: calc(100% - 120px - 120px);

    padding: 120px 80px;

    text-align: center;

    @media (max-width: 600px) {
      padding: 50px 20px;
      height: calc(100% - 50px - 50px);
    }

    @media (max-width: 420px) {
      padding: 20px;
      height: calc(100% - 20px - 20px);
    }
  }

  & ol {
    padding: 0;
    margin: 0;
  }

  & li,
  & button {
    width: 100%;
    // font-size: 36px;
    // line-height: 46px;

    font-size: calc(25px + (42 - 25) * ((100vw - 300px) / (1500 - 300)));
    line-height: 120%;

    color: rgba(253, 252, 252, 0.8);
    text-align: center;

    @media (max-width: 420px) {
      /* font-size: 22px; */

      font-size: calc(35px + 17 * ((100vw - 300px) / 1200));
      line-height: 133%;
    }
  }

  & li.divider {
    & svg {
      margin: 15px auto;
    }
  }

  & .sub-menu {
    margin: 5px 0 0 0;
  }

  & li.sub-menu-item {
    // font-size: 24px;
    // line-height: 31px;

    font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1500 - 300)));
    line-height: 120%;
    letter-spacing: 0.02em;

    @media (max-width: 420px) {
      font-size: 18px;
    }
  }
`;

const ItemRight = styled.div`
  position: absolute;
  right: 0;
`;

export const MobileSlideOutMenu = ({
  location,
  textColor,
  setIsMobileMenuOpen,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicMenu {
        data {
          body2 {
            ... on PrismicMenuBody2Divider {
              id
              slice_type
            }
            ... on PrismicMenuBody2PageLink {
              id
              slice_type
              primary {
                link {
                  document {
                    ... on PrismicDirectors {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicContact {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCommunity {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStories {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicEvents {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicExperiential {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicWhatWeDo {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicMission {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPhotographers {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicTheTalent {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStudios {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicMenuBody2ItemWithSubmenu {
              id
              slice_type
              primary {
                menu_item_title
              }
              items {
                override_page_title
                alternative_page_title
                page_link {
                  document {
                    ... on PrismicDirectors {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicContact {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicCommunity {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicEvents {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStories {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicWhatWeDo {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicExperiential {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicMission {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPage {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicPhotographers {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicTheTalent {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                    ... on PrismicStudios {
                      id
                      url
                      data {
                        title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setIsMenuOpen(false);
    setActiveSubMenu(null);
  }, [location]);

  useEffect(() => {
    setIsMobileMenuOpen(isMenuOpen);
  }, [isMenuOpen]);

  const links = data.prismicMenu.data.body2.map((link, index) => {
    if (link.slice_type === "divider") {
      return (
        <li key={link.id} className="divider">
          <div>
            <MenuSpike fill={`rgba(253,252,252,0.8)`} />
          </div>
        </li>
      );
    }

    if (link.slice_type === "page_link") {
      return (
        <li key={link.id}>
          <Link to={link.primary.link.document.url}>
            {link.primary.link.document.data.title.text}
          </Link>
        </li>
      );
    }

    if (link.slice_type === "item_with_submenu") {
      const subMenuItems = link.items.map((subMenuLink, index) => (
        <li key={subMenuLink.page_link.document.id} className="sub-menu-item">
          <Link to={subMenuLink.page_link.document.url}>
            {subMenuLink.override_page_title ? (
              <> {subMenuLink.alternative_page_title}</>
            ) : (
              <> {subMenuLink.page_link.document.data.title.text}</>
            )}
          </Link>
        </li>
      ));

      return (
        <li key={link.id}>
          <button
            onClick={() => {
              if (activeSubMenu === link.id) {
                setActiveSubMenu(null);
              } else {
                setActiveSubMenu(link.id);
              }
            }}
          >
            {link.primary.menu_item_title}
          </button>
          {activeSubMenu === link.id && (
            <ol className="sub-menu">{subMenuItems}</ol>
          )}
        </li>
      );
    }
  });

  return (
    <>
      <ItemRight className="flex-end" display={`flex`}>
        <MenuToggle
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          color={textColor}
          opacity={isMenuOpen === true ? 0 : 1}
          className={isMenuOpen === true ? `open` : ``}
        >
          <span>
            <MenuSpike fill={isMenuOpen ? `#FDFCFC` : textColor} />
          </span>
          <span>
            <MenuSpike fill={isMenuOpen ? `#FDFCFC` : textColor} />
          </span>
          <span>
            <MenuSpike fill={isMenuOpen ? `#FDFCFC` : textColor} />
          </span>
        </MenuToggle>
      </ItemRight>
      <Menu
        id="scrollable-menu"
        className={isMenuOpen === true ? `open` : ``}
        display={isMenuOpen === true ? `block` : `none`}
      >
        <ul className="menu-items">{links}</ul>
      </Menu>
    </>
  );
};
