import React from "react";
import CookieConsent from "react-cookie-consent";

// Components
import { CookieIcon } from "../icons/cookie-icon";

export const Cookie = () => (
  <CookieConsent
    buttonText={<CookieIcon />}
    containerClasses="cookie-consent-container"
    disableStyles={true}
    cookieName="gatsby-gdpr-google-analytics"
  />
);
