import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  @media (max-width: 768px) {
    width: auto;
    height: 10px;
  }
`;

export const CookieIcon = () => (
  <Icon
    width="142"
    height="15"
    viewBox="0 0 142 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.78846 0.192307H2.5L0 14.8077H2.03846L2.5 11.4615H4.61538L5.09615 14.8077H7.28846L4.78846 0.192307ZM3.19231 6.5C3.32692 5.57692 3.42308 4.44231 3.5 3.76923H3.59615C3.67308 4.44231 3.76923 5.57692 3.90385 6.5L4.38461 9.78846H2.73077L3.19231 6.5Z"
      fill="#DF3F1C"
    />
    <path
      d="M13.1279 2.90385C13.1279 2 13.5702 1.71154 14.2432 1.71154C14.9163 1.71154 15.2817 2 15.2817 2.90385V5.80769L17.2817 5.11538V3.11538C17.2817 1.09615 16.1471 0 14.2048 0C12.3202 0 10.9163 1.09615 10.9163 3.19231V11.8077C10.9163 14 12.3202 15 14.2048 15C16.2048 15 17.2817 13.9038 17.2817 11.8846V9.5L15.2817 8.80769V12.0962C15.2817 13 14.9163 13.2885 14.2432 13.2885C13.5702 13.2885 13.1279 13 13.1279 12.0962V2.90385Z"
      fill="#DF3F1C"
    />
    <path
      d="M23.7123 2.90385C23.7123 2 24.1546 1.71154 24.8277 1.71154C25.5007 1.71154 25.8661 2 25.8661 2.90385V5.80769L27.8661 5.11538V3.11538C27.8661 1.09615 26.7315 0 24.7892 0C22.9046 0 21.5007 1.09615 21.5007 3.19231V11.8077C21.5007 14 22.9046 15 24.7892 15C26.7892 15 27.8661 13.9038 27.8661 11.8846V9.5L25.8661 8.80769V12.0962C25.8661 13 25.5007 13.2885 24.8277 13.2885C24.1546 13.2885 23.7123 13 23.7123 12.0962V2.90385Z"
      fill="#DF3F1C"
    />
    <path
      d="M36.7001 6.36538H34.4117V1.98077H37.4309V0.192307H32.1809V14.8077H37.5847V13.0385H34.4117V8.15385H36.7001V6.36538Z"
      fill="#DF3F1C"
    />
    <path
      d="M47.9659 3.17308C47.9659 0.865385 46.7159 0.192307 45.139 0.192307H41.6197V14.8077H43.8505V8.57692H45.1197C46.7159 8.57692 47.9659 7.88462 47.9659 5.59615V3.17308ZM45.7543 5.82692C45.7543 6.67308 45.4659 6.88461 44.7159 6.88461H43.8505V1.90385H44.7159C45.4659 1.90385 45.7543 2.13461 45.7543 2.96154V5.82692Z"
      fill="#DF3F1C"
    />
    <path
      d="M55.7114 1.98077H57.9614V0.192307H51.2306V1.98077H53.4806V14.8077H55.7114V1.98077Z"
      fill="#DF3F1C"
    />
    <path
      d="M69.1037 2.90385C69.1037 2 69.546 1.71154 70.219 1.71154C70.8921 1.71154 71.2575 2 71.2575 2.90385V5.80769L73.2575 5.11538V3.11538C73.2575 1.09615 72.1229 0 70.1806 0C68.296 0 66.8921 1.09615 66.8921 3.19231V11.8077C66.8921 14 68.296 15 70.1806 15C72.1806 15 73.2575 13.9038 73.2575 11.8846V9.5L71.2575 8.80769V12.0962C71.2575 13 70.8921 13.2885 70.219 13.2885C69.546 13.2885 69.1037 13 69.1037 12.0962V2.90385Z"
      fill="#DF3F1C"
    />
    <path
      d="M84.1496 3.17308C84.1496 0.942308 82.6304 0 80.8035 0C79.015 0 77.4766 0.942308 77.4766 3.17308V11.8269C77.4766 14.0577 79.015 15 80.8035 15C82.6304 15 84.1496 14.0577 84.1496 11.8269V3.17308ZM81.9189 12.0962C81.9189 13 81.4766 13.2885 80.8035 13.2885C80.1304 13.2885 79.6881 13 79.6881 12.0962V2.90385C79.6881 2 80.1304 1.71154 80.8035 1.71154C81.4766 1.71154 81.9189 2 81.9189 2.90385V12.0962Z"
      fill="#DF3F1C"
    />
    <path
      d="M95.1097 3.17308C95.1097 0.942308 93.5904 0 91.7635 0C89.975 0 88.4366 0.942308 88.4366 3.17308V11.8269C88.4366 14.0577 89.975 15 91.7635 15C93.5904 15 95.1097 14.0577 95.1097 11.8269V3.17308ZM92.8789 12.0962C92.8789 13 92.4366 13.2885 91.7635 13.2885C91.0904 13.2885 90.6481 13 90.6481 12.0962V2.90385C90.6481 2 91.0904 1.71154 91.7635 1.71154C92.4366 1.71154 92.8789 2 92.8789 2.90385V12.0962Z"
      fill="#DF3F1C"
    />
    <path
      d="M104.185 14.8077H106.493L103.3 6.65385L106.474 0.192307H104.32L101.781 5.98077H101.666V0.192307H99.4736V14.8077H101.666V8.51923H101.781L104.185 14.8077Z"
      fill="#DF3F1C"
    />
    <path
      d="M112.514 0.192307H110.283V14.8077H112.514V0.192307Z"
      fill="#DF3F1C"
    />
    <path
      d="M121.5 6.36538H119.211V1.98077H122.23V0.192307H116.98V14.8077H122.384V13.0385H119.211V8.15385H121.5V6.36538Z"
      fill="#DF3F1C"
    />
    <path
      d="M126.111 9.61538C126.054 10.0577 126.015 10.8077 126.015 11.4038C126.015 13.7308 127.035 15 129.188 15C131.111 15 132.419 14.1538 132.419 11.4231C132.419 10.2885 131.842 8.82692 131.4 8.23077C130.746 7.32692 129.515 5.80769 129.054 5.34615C128.573 4.73077 128.265 4 128.265 3.13461C128.265 2.11538 128.496 1.73077 129.246 1.73077C129.861 1.73077 130.246 2.11538 130.246 3.21154C130.246 3.76923 130.188 4.34615 130.131 4.98077L132.035 4.5C132.111 4.05769 132.169 3.57692 132.169 3.03846C132.169 1.01923 131.188 0 129.208 0C127.342 0 126.131 0.961538 126.131 3.32692C126.131 4.71154 126.592 5.88462 127.188 6.63462C127.708 7.25 128.804 8.53846 129.419 9.30769C129.881 9.90385 130.246 10.8269 130.246 11.7308C130.246 12.8654 130.035 13.2885 129.188 13.2885C128.361 13.2885 128.054 12.7885 128.054 11.4038C128.054 10.5769 128.092 9.92308 128.169 9.09615L126.111 9.61538Z"
      fill="#DF3F1C"
    />
    <path
      d="M138.845 6.40385C137.999 7.53846 137.672 8.5 137.672 9.94231C137.672 10.6923 137.768 11.0385 137.941 11.4038H139.287C139.23 11.0769 139.191 10.7885 139.191 10.3846C139.191 9.05769 139.403 8.17308 140.153 7.26923C141.287 5.86538 141.999 5.17308 141.999 3.23077C141.999 1.36538 141.21 0 139.037 0C136.903 0 135.96 1.30769 135.96 3.38461C135.96 4 136.114 4.76923 136.268 5.15385L137.845 5.55769C137.749 5.09615 137.691 4.07692 137.691 3.59615C137.691 2.51923 137.903 1.59615 138.749 1.59615C139.614 1.59615 139.787 2.34615 139.787 3.25C139.787 4.51923 139.73 5.21154 138.845 6.40385ZM137.499 12.6731V14.8077H139.653V12.6731H137.499Z"
      fill="#DF3F1C"
    />
  </Icon>
);
