import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
// import AniLink from "gatsby-plugin-transition-link/AniLink";

const Icon = styled.svg`
  transition: 500ms height ease;

  height: ${props => (props.height === true ? `80px` : `115px`)};
  display: ${props => props.display};
  height: ${props => props.height};

  @media (max-width: 768px) {
    width: auto;
    height: 68px;

    transition: 250ms opacity ease, 300ms transform ease;
    opacity: ${props => props.opacity};

    &.visible {
      transform: translateY(0);
    }

    &.hidden {
      transform: translateY(-90px);
    }
  }
`;

export const ParkVillageIcon = ({ display, fill, shrinkLogo, fadeOutLogo }) => (
  <Link to={`/`} className="flex-center">
    <Icon
      width="102"
      height={shrinkLogo === true ? `80px` : `111px`}
      viewBox="0 0 102 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      display={display}
      opacity={fadeOutLogo === true ? 0 : 1}
      className={shrinkLogo === true ? `hidden` : `visible`}
    >
      <g id="P">
        <path
          d="M102,60.5L76.8,50.2l-0.3-0.1l-5.8,3.3v-5.7l-21.8-8.9L41.6,43v0.3l0,12.2l4.8,2l16.1,48.2L73,110l0,0l0.3,0.1l7.6-4.3
		l12.2-29.3l1.3,0.5h0l0.3,0.1L102,73L102,60.5z M74.5,69l-3.6,8.5L66,63l3.2-1.8l0,5.7L74.5,69z M70.2,79.1l-3.9,9.1l-8.3-26
		l5.1,2.1h0l0.3,0.1l1.6-0.9L70.2,79.1z M69.5,54.1l-0.3,0.2v0.3v5.2l-4.2,2.4l0,0l-1.1,0.6l0-10.5l5.6-3.2L69.5,54.1z M49,40.1
		l19.8,8.1l-5.5,3.1l-19.8-8.1L49,40.1z M79.9,105l-5.5,3.2l14-33.5l3.6,1.5L79.9,105z M94.6,64.1l-23.5-9.6l5.5-3.1l23.5,9.6
		L94.6,64.1z M95.2,75.6l0-10.5l5.6-3.2l0,10.5L95.2,75.6z"
          fill={fill}
        />
        <path
          d="M49.1,24.6c1.8-3.4,2.7-7.1,2.7-11c0-1.7-0.4-3.6-1.2-5.3c-0.9-2-2.2-3.5-3.9-4.5c-0.6-0.3-1.2-0.6-1.9-0.8l-6.3-2.5
		c-0.1-0.1-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.1-0.5-0.2l0,0c0,0-0.1,0-0.1,0c-3-0.8-6.7,0.1-10.9,2.5l0,0L0,18.2v0.3l0,12.2l6.4,2.6
		l0,22.9L0,59.9v0.3l0,12.2l7.3,3h0l0.3,0.1L31.2,62l0-12.5l-6.9-2.8v-3.2L37,36.3l0,0C42.6,33.1,46.6,29.2,49.1,24.6z M33.4,18.9
		L33.4,18.9l-0.1,0.5l-0.1,0.4L33,20.1l-0.2,0.4l-0.2,0.4l-0.3,0.5l-0.4,0.5l-0.4,0.5L30.9,23L30,23.7l-1,0.7l-1.3,0.8l-3.3,1.9
		v-3.1L33.4,18.9z M24.3,34l0-5.5l3.9-2.2l0,0l0,0l1.4-0.8l1.1-0.8l1-0.8l0.7-0.7l0.5-0.5l0.4-0.6l0.4-0.6l0.2-0.4l0.2-0.4l0.2-0.4
		l0.2-0.4l0.1-0.5l0.1-0.5l0.1-0.7l1.6-0.9l0.8-0.4l0.6-0.2l0.6-0.1l0.5,0l0.3,0l0.3,0.1l0.2,0.1l0.2,0.1l0.2,0.2l0.2,0.2l0.2,0.3
		l0.1,0.4l0.1,0.4l0.1,0.5l0,0.6l0,0.7l-0.1,0.7l-0.1,0.7l-0.1,0.4l-0.1,0.4l-0.1,0.4L40,22.9l-0.2,0.4l-0.2,0.4l-0.3,0.5l-0.4,0.5
		l-0.4,0.5l-0.6,0.6L37,26.5l-1,0.7l-1.3,0.8L24.3,34z M7.6,33.9l5.7-3.3l0,28l-5.9-2.4l0-22.4L7.6,33.9z M27.5,3.8L27.5,3.8
		L27.5,3.8c3.9-2.3,7.3-3.1,10.1-2.4l3.7,1.5c-2.2,0.2-4.7,1.2-7.4,2.7l0,0L7.5,20.8l-5.6-2.3L27.5,3.8z M1.2,30l0-10.6L7,21.8
		l0,10.6L1.2,30z M1.2,71.7l0-10.6L7,63.5L7,74L1.2,71.7z M1.9,60.1L7,57.2l5.6,2.3l-5.1,2.9L1.9,60.1z M29.3,50l-5,2.9v-4.9
		L29.3,50z"
          fill={fill}
        />
      </g>
    </Icon>
  </Link>
);
