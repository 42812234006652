import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

export const DefaultSEO = () => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          text {
            text
          }
        }
      }
    }
  `);

  return (
    <Helmet
      title={`Park Village`}
      meta={[
        {
          name: "viewport",
          content:
            "width=device-width, user-scalable=no, initial-scale=1, maximum-scale=1",
        },
        {
          name: "description",
          content: `${data.prismicHomepage.data.text.text}`,
        },
        {
          name: "og:title",
          content: `Park Village`,
        },
        {
          name: "og:type",
          content: `website`,
        },
        {
          name: "og:image",
          content: `https://www.parkvillage.co.uk/icons/favicon.jpg`,
        },
        {
          name: "og:image:secure_url",
          content: `https://www.parkvillage.co.uk/icons/favicon.jpg`,
        },
        {
          name: "og:description",
          content: `${data.prismicHomepage.data.text.text}`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "twitter:title",
          content: `Park Village`,
        },
        {
          name: "twitter:description",
          content: `${data.prismicHomepage.data.text.text}`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `https://www.parkvillage.co.uk/icons/favicon.jpg`,
        },
      ]}
      link={[
        {
          rel: "dns-prefetch",
          href: "https://images.prismic.io/parkvillage",
        },
        {
          rel: "preconnect",
          href: "https://images.prismic.io/parkvillage",
        },
        {
          rel: "dns-prefetch",
          href: "https://player.vimeo.com",
        },
        {
          rel: "preconnect",
          href: "https://player.vimeo.com",
        },
        {
          rel: "dns-prefetch",
          href: "https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com",
        },
        {
          rel: "preconnect",
          href: "https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com",
        },
        {
          rel: "dns-prefetch",
          href: "https://vod-progressive.akamaized.net",
        },
        {
          rel: "preconnect",
          href: "https://vod-progressive.akamaized.net",
        },
        {
          rel: "dns-prefetch",
          href: "https://i.vimeocdn.com",
        },
        {
          rel: "preconnect",
          href: "https://i.vimeocdn.com",
        },
        {
          rel: "dns-prefetch",
          href: "https://f.vimeocdn.com",
        },
        {
          rel: "preconnect",
          href: "https://f.vimeocdn.com",
        },
        {
          rel: "preload",
          href: "/fonts/fonts.css",
          as: "style",
        },
        {
          rel: "preload",
          href: "/fonts/CaslonDoric-Regular-Web.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/CaslonDoric-Regular-Web.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/CaslonDoric-RegularItalic-Web.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/CaslonDoric-RegularItalic-Web.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-Bold.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-Bold.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-Book.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-Book.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-DemiBold.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-Bold.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-Light.woff2",
          as: "font",
          crossOrigin: "anonymous",
        },
        {
          rel: "preload",
          href: "/fonts/LDNBloomsburyOldStyle-Bold.woff",
          as: "font",
          crossOrigin: "anonymous",
        },
      ]}
    />
  );
};
