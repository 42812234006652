import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html {
  main{
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  main{
    opacity: 1;
  }
}

*:focus {
  outline: none;
}

body {
  font-family: "LDNBloomsburyOldStyle-Book", Times, "Times New Roman", serif;
  font-weight: normal;
  font-style: normal;

  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);

  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;

  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #1D1D1B;
  margin: 0;

  word-break: break-word;
}

input {
  border-radius: 0;
}

strong{
  font-weight: normal;
  font-style: normal;
}

h1,h2,h3,h4,h5{
  font-family: "LDNBloomsburyOldStyle-Book", Times, "Times New Roman", serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0.3px;
}

/* Title 2 */
h2 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
}

.caslon-doric {
  font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    appearance: none;
    background-color: transparent;
    cursor: pointer;

    border: none;
    color: #1d1d1b;

    transition: 150ms all ease;

    padding: 0;
    margin:0;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}


/*--------------------------------------------------------------
  ## Modals
  --------------------------------------------------------------*/



.ReactModal__Overlay,
.ReactModalPortal{
  z-index: 1000;

  background-color: #fff;

}

.gallery-modal{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #fff;
  z-index: 1000;

}

/*--------------------------------------------------------------
  ## Cookie consent
  --------------------------------------------------------------*/

.cookie-consent-container{
  position: fixed;

  left:15px;
  bottom:35px !important;

  line-height: 1;

  cursor: pointer;

z-index: 100;

  & button{
    line-height: 1;
  }
}

`;

export default GlobalStyle;
