import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Icon = styled.svg`
  /* opacity: 0.5;

  @media (max-width: 768px) {
    width: 42px;
    height: auto;
  } */
`;

const BackButton = styled.div`
  display: inline-block;
`;

export const BackIcon = ({ fill, backButtonURL }) => (
  <BackButton>
    <Link to={backButtonURL}>
      <Icon
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="18"
        viewBox="0 0 23 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.7 0L0 7.27V7.28992V9.71V9.71992H0.0118203L8.7 17.01H10.02L4.76018 9.71992H22.68V7.28992H4.75411L10.02 0H8.7Z"
          fill="#1D1D1B"
        />
      </Icon>
    </Link>
  </BackButton>
);
